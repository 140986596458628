import React, { Component } from "react";
import { CardBody, Col, Row,  Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import * as BB from "../../../../components/BBComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as taskActions from "../../../../redux/actions/taskActions";
import { Redirect, withRouter } from "react-router-dom";
import { CustomCard } from "../../../../components/custom";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import moment from "moment";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import DataSource from 'devextreme/data/data_source';
import jwt_decode from 'jwt-decode';
import { callAPI, callApiGet } from "../../../../components/Axios";
import Swal from "sweetalert2";
import { timeoutPopup } from "../../../../components/BBComponent";
import { t } from "i18next";
import TaskTerminationModal from "./modals/terminations";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
class TaskTracking extends Component {
  constructor(props) {
    super(props);
        
    let user = null;
      var token = localStorage.getItem("token");

      if (token) {
         user = jwt_decode(token);
      }

      this.state = {
        data:[],
      isLoading: true,
      selectedTaskCreateDateStart: "",
      selectedTaskCreateDateEnd: "",
      departmentUsers: [],
      taskStatus: [],
      departmentTaskStatus:[],
      workStatus: [],
      companies: [],
      searchModeOption: 'startswith',
      searchExprOption: 'Name',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      projectNumber:'',
      rejectStatus:[],
      selectedDegreeOfUrency: "",
      selectedPoolID: null,
      showDetail: true,
      selectedExpectedEndDate: undefined,
      selectedMainDropdown: this.props.location.search
        ? this.props.location.search === "?WatcherTasks"
          ? 5
          : this.props.location.search === "?Pool"
          ? 4
          : this.props.location.search === "?OwnTasks"
          ? 6
          : this.props.location.search === "?TaskAssignment"
          ? 9
          : 1
        : 1,
      IsDepartmentTasks: false,
      IsFinishedTasks: false,
      IsAllTasks: false,
      IsWatcherTasks: this.props.location.search === "?WatcherTasks" ? true : false,
      IsPoolTasks: this.props.location.search === "?Pool" ? true : false,
      IsMyTasks: this.props.location.search === "?OwnTasks" ? true : false,
      IsAssigment: this.props.location.search === "?TaskAssignment" ? true : false,
      visibleAciklamaEkle: false,   
      selectedShareOther: false,
      selectedCommentDescription: "",
      showClosedTasks: false,
      //showOpenTasks: false,
      selectedTaskResponsibleUser: user.ID,
      selectedTaskStatus: "ac2df37b-8ac8-e811-80f6-005056b839bb",
      selectedTaskStatusForConfirm: "00000000-0000-0000-0000-000000000000",
      selectedWorkStatus: "00000000-0000-0000-0000-000000000000",
      selectedCompany: "00000000-0000-0000-0000-000000000000",
      modalOpen: false,
      isPopupVisible: false,
      loginUser :user,
      confirmTasks:false,
      rejectTask:false,
      taskTrackingButtonVis:"hidden",
      myPools:undefined,
      selectedPools: "00000000-0000-0000-0000-000000000000",
      isTerminatePopupVisible: false,
      isMultiselect : false,
      selectedItemsList: [],
      categoryID: null,
      statusID: null,
      isPopupVisible2:false,
      visibleSonlandir:false,
      customElements: null,
      terminationStatus: [],
      terminateTemplate: [],
      selectedTerminationType: undefined,
      selectedTerminationTemplate: "",
      selectedTerminationDescription: "",
      selectedTerminationFile: [],
      selectedCustomControl: [],
      taskID: this.props.location.state
        ? this.props.location.state.taskID
        : this.props.location.search
        ? this.props.location.search.toString().replace("?", "")
        : null,
      NotifyDepartment: false,
      redirect: this.props.location.state
        ? this.props.location.state.taskID
        : this.props.location.search
        ? false
        : true,
      };
      console.log("deneme", this.state);
    let { t } = this.props;
    this.languages = {
      hata: t("hata"),
      onayVer: t("onayVer"),
      iptal: t("iptal"),
      bilgimdeOlanTalepler :t("bilgimdeOlanTalepler"),
      izleyicisiOlduğumTalepler:t("izleyicisiOlduğumTalepler"),
      onayladıgımTalepler:t("onayladığımTalepler"),
      reddettiğimTalepler:t("reddettiğimTalepler"),
      oluşturduğumTalepler:t("oluşturduğumTalepler"),
      üzerimdekiTalepler:t("üzerimdekiTalepler"),
      görevAtananTalepler:t("görevAtananTalepler"),
      ekibinÜzerindekiTalepler:t("ekibinÜzerindekiTalepler"),
      tamamlananTalepler:t("tamamlananTalepler"),
      firma:t("firma"),
      uygula:t("uygula"),
      vazgec: t("vazgec"),
      vazgec2: t("talepAciklamasi"),
      devral: t("devral"),
      tamam:t("tamam"),
      eminMisiniz: t("eminMisiniz"),
      talebinizBaşkaBirKullanıcıTarafındanDevranıldığıİçinBuTalepÜzerindeİptalİşlemiYapamazsınız: t("talebinizBaşkaBirKullanıcıTarafındanDevranıldığıİçinBuTalepÜzerindeİptalİşlemiYapamazsınız!"),
      talepDevralindi: t("talepDevralindi"),
      talebiDevralacaksiniz: t("talebiDevralacaksiniz"),
      onayAciklamasiGiriniz: t("onayAciklamasiGiriniz"),
      iptalAciklamasiGiriniz: t("iptalAciklamasiGiriniz"),
      onayBasariylaVerilmistir: t("onayBasariylaVerilmistir"),
      talepBasariylaIptalEdilmistir: t("talepBasariylaIptalEdilmistir"),
      talepBasariylaDevredildi: t("talepBasariylaDevredildi"),
      havuzAlaniZorunludur: t("havuzAlaniZorunludur"),
      durumAlaniZorunludur: t("durumAlaniZorunludur"),
      talepDurumDegistirmeBasarili: t("talepDurumDegistirmeBasarili"),
      aciklamaAlaniZorunludur: t("aciklamaAlaniZorunludur"),
      dosyaEklemeZorunludur: t("dosyaEklemeZorunludur"),
      hizliTalepOlusturAciklama:t("hizliTalepOlusturAciklama"),
      iptalAciklamasıGiriniz:t("iptalAciklamasiGiriniz"),
      toplamTalepSayisi:t("toplamTalepSayisi"),
      oluşturmaBaşlangıçTarihi:t("oluşturmaBaşlangıçTarihi"),
      kapanışBaşlangıçTarihi:t("kapanışBaşlangıçTarihi"),
      detaylıFiltre:t("detaylıFiltre"),
      kapanışBitişTarihi:t("kapanışBitişTarihi"),
      kapananlarıDahilEt:t("kapananlarıDahilEt"),
      oluşturmaBitişTarihi:t("oluşturmaBitişTarihi"),
      işlemDurumu:t("işlemDurumu"),
      talepDurumu:t("talepDurumu"),
      talepBasariylaSonlandirildi: t("talepBasariylaSonlandirildi"),
      ProjeNumarası:t("ProjeNumarası"),
      TalepişlemdurumutalebikapatmayauygundeğilÖncelikleİşlemeAlınız:t("TalepişlemdurumutalebikapatmayauygundeğilÖncelikleİşlemeAlınız"),
      Tamam:t("Tamam"),
      sonlandirmaAciklamasiAlaniZorunludur: t(
        "sonlandirmaAciklamasiAlaniZorunludur"
      ),
      talepBasariylaIptalEdilmistir:t("talepBasariylaIptalEdilmistir"),
      talebinAciliyetiBasariylaDegistirildi: t(
        "talebinAciliyetiBasariylaDegistirildi"
      ),
      talebinAciliyetiDegistirilirkenHataAlindi: t(
        "talebinAciliyetiDegistirilirkenHataAlindi"
      ),
      sonlandirmaTuruAlaniZorunludur: t("sonlandirmaTuruAlaniZorunludur"),
      talepDurumuBeklemedeOlanHavuzaGonderilmez: t(
        "talepDurumuBeklemedeOlanHavuzaGonderilmez"
      ),
      ozelAlanZorunlu: t("ozelAlanZorunlu"),
    };

    this.gridRef = React.createRef();
    this.searchModeOptionChanged = ({ value }) => {
      this.setState({ searchModeOption: value });
    };
    this.searchExprOptionChanged = ({ value }) => {
      this.setState({ searchExprOption: value });
    };
    this.searchTimeoutOptionChanged = ({ value }) => {
      this.setState({ searchTimeoutOption: value });
    };
    this.minSearchLengthOptionChanged = ({ value }) => {
      this.setState({ minSearchLengthOption: value });
    };
    this.showDataBeforeSearchOptionChanged = ({ value }) => {
      this.setState({ showDataBeforeSearchOption: value });
    };
  }

    componentDidMount() {
      if (this.props.location.search === '?WatcherTasks' || this.props.location.search === '?OwnTasks' || this.props.location.search === '?TaskAssignment') {
      this.setState({ isMultiselect: false });
    } else {
      this.setState({ isMultiselect: true });
    }
    if (
      this.state.loginUser.IsLastUser === "True" &&
      this.props.location.search === "?WatcherTasks"
    ) {
      this.setState(
        {
          selectedMainDropdown: 5,
          IsWatcherTasks: true,
          isMultiselect: false,
        },
        this.getData
      );
    } else if (
      this.state.loginUser.IsLastUser === "True" &&
      this.props.location.pathname === "/TaskTracking"
    ) {
      this.setState(
        {
          selectedMainDropdown: 6,
          IsMyTasks: true,
          isMultiselect: true,
        },
        this.getData
      );
    } 
    else {
      this.getData();
    }
    this.getTaskStatusList()
   this.getTaskWorkStatus();
   this.getDepartmentStatusList();
      this.getMyPools();
      this.GetCustomElementsForClosing(this.state.data);
  }

 
  getDepartmentStatusList(){

    var url = 'api/TaskTracking/GetDepartmentStatusDropDown' 
    callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          {
            
            this.setState({departmentTaskStatus: res.data});
            
         } 

        })
  }

  getTaskStatusList(){

    var url = 'api/TaskTracking/GetTaskStatusList' 
    callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          {
            
            var index0=res.data[0]
            this.setState({taskStatus: res.data , rejectStatus:index0}); 
            
         } 

        })
  }

  getTaskWorkStatus() {

    var url = 'api/TaskTracking/GetTaskWorkStatus' 
    callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          {
            this.setState({workStatus: res.data});
          } 

        })

  }
  getMyPools() {

    var url = 'api/TaskTracking/GetMyPool' 
    callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          {
            this.setState({myPools: res.data});
          } 

        })

  }

    getData(TaskID) {
    if(this.state.IsMyTasks){
        this.setState({taskTrackingButtonVis:"inherit"})
    }else{
      this.setState({taskTrackingButtonVis:"hidden"})

    }
        let taskID = TaskID !== undefined ? TaskID : this.state.taskID;
    let {
      selectedTaskCreateDateStart,
      selectedTaskCreateDateEnd,
      IsDepartmentTasks,
      IsFinishedTasks,
      IsAllTasks,
      IsPoolTasks,
      IsMyTasks,
      showClosedTasks,
      IsWatcherTasks,
      IsAssigment,
      selectedTaskResponsibleUser,
      selectedTaskStatus,
      selectedWorkStatus,
      selectedCompany,
      confirmTasks,
      rejectTask,
      selectedPools,
      projectNumber
    } = this.state;

    let startDate = selectedTaskCreateDateStart
      ? moment(selectedTaskCreateDateStart).format("YYYY-MM-DD")
      : null;
    let endDate = selectedTaskCreateDateEnd
      ? moment(selectedTaskCreateDateEnd).format("YYYY-MM-DD 23:59")
      : null;


    let filter = {
      CreateDateStart: startDate,
      CreateDateEnd: endDate,
      IsDepartmentTasks: IsDepartmentTasks,
      IsFinishedTasks: IsFinishedTasks,
      IsAllTasks: IsAllTasks,
      IsPoolTasks: IsPoolTasks,
      IsMyTasks: IsMyTasks,
      showClosedTasks: showClosedTasks,
      selectedTaskStatus: selectedTaskStatus,
      IsWatcherTasks: IsWatcherTasks,
      IsAssigment: IsAssigment,
      selectedTaskResponsibleUser: selectedTaskResponsibleUser,
      selectedWorkStatus: selectedWorkStatus,
      selectedCompany:selectedCompany,
      projectNumber:projectNumber,
      confirmTasks:confirmTasks,
      rejectTask:rejectTask,
      selectedPool:selectedPools
    };

    this.props.actions.getTaskInTaskTracking(filter).then(() => {
      this.props.actions.isLoading(false);

      this.setState({ isLoading: false }, this.clearFilter);

      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.instance.clearFilter();
      }
    });

    var url = '/api/OwnConfirmTask/GetDepartmentUserDropdown' 
    callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          {
            
            this.setState({ departmentUsers: res.data });
          } 

        })
   
    var url = '/api/TaskTracking/GetCompanies' 
    callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          {
            this.setState({ companies: res.data });
          } 

        })
        var url = 'api/TaskTracking/GetMyPool' 
        callAPI(
            callApiGet,
            url,
            {},
            async (res) => {
              {
                this.setState({myPools: res.data});
              } 
    
            })
  }
    GetCustomElementsForClosing = (data) =>
        new Promise((resolve) => {
            var url =
                "/api/TaskDetail/GetCustomElementsForClosing?id=" + data.CategoryID;
            callAPI(callApiGet, url, {}, async (customElements) => {
                this.setState({
                    customElements: customElements.data ? customElements.data : null,
                });
                resolve(true);
            });
        });

    openPopup(categoryID, selectedItemsList) {
        var url = "/api/TaskDetail/GetCustomElementsForClosing?id=" + categoryID;
        callAPI(callApiGet, url, {}, async (customElements) => {
            if (customElements.data) {
                let sortedCustomControls = customElements.data.sort(function (a, b) {
                    return a.OrderNumber - b.OrderNumber;
                });
                this.setState({ customElements: sortedCustomControls });
            } else {
                this.setState({ customElements: null });
            }
        });

        const terminationStatusData = new CustomStore({
            loadMode: "raw",
            key: "ID",
            load: function () {
                return BB.FetchJSON("/api/TaskDetail/GetTerminationStatuses").then(
                    (data) => {
                        return data;
                    }
                );
            },
        });

        this.setState({ terminationStatus: terminationStatusData });

        const terminateTemplateData = new CustomStore({
            loadMode: "raw",
            key: "ID",
            load: function () {
                return BB.FetchJSON("/api/TaskDetail/GetTerminateTemplates").then(
                    (data) => {
                        return data;
                    }
                );
            },
        });

        this.setState({ terminateTemplate: terminateTemplateData });

        this.setState({ visibleSonlandir: true, categoryID, selectedItemsList });
    }
    deleteSelectedFile = (name) => {
        let newFiles = [];
        this.state.selectedTaskFiles.map((item, i) => {
            if (item.name !== name) {
                newFiles.push(item);
            }
        });

        this.setState({ selectedTaskFiles: newFiles });
        this.setState({ ComponentFileUpload: newFiles });
    };

    deleteSelectedTerminationFile = (name) => {
        let newFiles = [];
        this.state.selectedTerminationFile.map((item, i) => {
            if (item.name !== name) {
                newFiles.push(item);
            }
        });
        this.setState({ selectedTerminationFile: newFiles });
        this.setState({ ComponentFileUpload: newFiles });
    };
    closePopup = () => {
        this.setState({ visibleSonlandir: false });
    };
    handleSubmit = () => {
        // Handle the form submission logic here
        this.closePopup();
    };
    handleChange(id, value, description) {
        let currentSelectedCustomControl = this.state.selectedCustomControl;
        let isCustomControl = this.state.customElements.filter((x) => x.ID == id);

        if (isCustomControl.length > 0) {
            let hasAlready = currentSelectedCustomControl.filter((x) => x.ID == id);

            if (hasAlready.length > 0) {
                let currentIndex = currentSelectedCustomControl.indexOf(hasAlready);
                currentSelectedCustomControl.splice(currentIndex, 1);

                if (isCustomControl[0].CustomControlType === 2) {
                    let item = { ID: id, Value: value };
                    currentSelectedCustomControl.push(item);
                } else {
                    let item = { ID: id, Value: value };
                    currentSelectedCustomControl.push(item);
                }
            } else {
                if (isCustomControl[0].CustomControlType === 2) {
                    let item = { ID: id, Value: value };
                    currentSelectedCustomControl.push(item);
                } else {
                    let item = { ID: id, Value: value };
                    currentSelectedCustomControl.push(item);
                }
            }
            this.setState({ selectedCustomControl: currentSelectedCustomControl });
        }
    //Üzerimdeki Talepler
    if(id==="projectNumber") {
      this.setState({ [id]: value });
        }
    if (id === "selectedTerminationDescription") {
      this.setState({ selectedTerminationDescription: value });
    }
    if (id === "selectedTerminationType") {
      this.setState({ selectedTerminationType: value });
    }
    if (id === "selectedTerminationTemplate") {
      this.setState({
        selectedTerminationTemplate: value,
        selectedTerminationDescription: description,
      });
        }
    if (id === "selectedTerminationFile") {
      this.setState({ selectedTerminationFile: value });
    }
    if (id === "NotifyDepartment") {
      this.setState({ NotifyDepartment: !this.state.NotifyDepartment });
    }

    if (id === "selectedMainDropdown" && value === 1) {
      const taskSt = "00000000-0000-0000-0000-000000000000";
      this.setState(
        {
          selectedMainDropdown: value,
          isLoading: true,
          IsDepartmentTasks: false,
          IsFinishedTasks: false,
          IsAllTasks: false,
          IsPoolTasks: false,
          IsMyTasks: false,
          IsWatcherTasks:false,
          IsAssigment:false,
          selectedTaskCreateDateStart: undefined,
          selectedTaskCreateDateEnd: undefined,
          selectedTaskStatus: "00000000-0000-0000-0000-000000000000",
          selectedWorkStatus: undefined,
          confirmTasks:false,
          rejectTask:false,
          selectedCompany:undefined,
          selectedPools:"00000000-0000-0000-0000-000000000000",
          isMultiselect: true,
        },
        this.getData
      );
      this.props.history.push("/TaskTracking");
    }

    //Ekibin üzerindeki talepler
    if (id === "selectedMainDropdown" && value === 2) {
      const taskSt = "ac2df37b-8ac8-e811-80f6-005056b839bb";
      this.setState(
        {
          selectedMainDropdown: value,
          selectedTaskResponsibleUser: "00000000-0000-0000-0000-000000000000",
          selectedWorkStatus: "00000000-0000-0000-0000-000000000000",
          selectedCompany:"00000000-0000-0000-0000-000000000000",
          isLoading: true,
          IsDepartmentTasks: true,
          IsFinishedTasks: false,
          IsAllTasks: false,
          IsPoolTasks: false,
          IsMyTasks: false,
          confirmTasks:false,
          rejectTask:false,
          IsWatcherTasks: false,
          IsAssigment: false,
          selectedTaskCreateDateStart: undefined,
          selectedTaskCreateDateEnd: undefined,
          selectedTaskStatus: taskSt,
          projectNumber:null,
          isMultiselect: false,
        },
        this.getData
      );
      this.props.history.push("/TaskTracking");
    }

    //Tamamlanan Talepler
    if (id === "selectedMainDropdown" && value === 3) {
      const todayDate = moment().format("YYYY-MM-DD HH:MM");
      const oneMonthAgoDate = moment().subtract(1, "months").calendar();
      this.setState(
        {
          selectedMainDropdown: value,
          selectedTaskResponsibleUser: this.state.selectedTaskResponsibleUser,
          selectedTaskStatus: "00000000-0000-0000-0000-000000000000",
          IsFinishedTasks: true,
          IsDepartmentTasks: false,
          IsAllTasks: false,
          confirmTasks:false,
          rejectTask:false,
          IsPoolTasks: false,
          isLoading: true,
          IsMyTasks: false,
          selectedTaskCreateDateStart: todayDate,
          selectedTaskCreateDateEnd: undefined,
          isMultiselect: false,
        },
        this.getData
      );
      this.props.history.push("/TaskTracking");
    }

    //Havuzda Bekleyen Talepler
    if (id === "selectedMainDropdown" && value === 4) {
      this.setState(
        {
          selectedMainDropdown: value,
          isLoading: true,
          IsFinishedTasks: false,
          IsDepartmentTasks: false,
          IsAllTasks: false,
          confirmTasks:false,
          rejectTask:false,
          IsPoolTasks: true,
          IsMyTasks: false,
          IsWatcherTasks: false,
          IsAssigment: false,
          selectedTaskCreateDateStart: undefined,
          selectedTaskCreateDateEnd: undefined,
          isMultiselect: false,
        },
        this.getData
      );
      this.props.history.push("/TaskTracking");
    } 

    //Bilgimde olan talepler
    if (id === "selectedMainDropdown" && value === 5) {
      let IsWatcherTasks = true;

      this.setState(
        {
          selectedMainDropdown: value,
          isLoading: true,
          IsAllTasks: false,
          IsWatcherTasks: IsWatcherTasks,
          selectedTaskStatus: "00000000-0000-0000-0000-000000000000",
          IsFinishedTasks: false,
          IsDepartmentTasks: false,
          confirmTasks:false,
          rejectTask:false,
          IsPoolTasks: false,
          IsMyTasks: false,
          selectedTaskCreateDateStart: undefined,
          selectedTaskCreateDateEnd: undefined,
          isMultiselect: false,
        },
          this.getData
      );
      this.props.history.push("/TaskTracking?WatcherTasks");
    }

    //Tüm Talepler
    if (id === "selectedMainDropdown" && value === 6) {
      this.setState(
        {
          selectedMainDropdown: value,
          isLoading: true,
          selectedTaskStatus: "00000000-0000-0000-0000-000000000000",
          IsAllTasks: false,
          IsFinishedTasks: false,
          IsDepartmentTasks: false,
          confirmTasks:false,
          rejectTask:false,
          IsPoolTasks: false,
          IsMyTasks: true,
          IsWatcherTasks: false,
          selectedTaskCreateDateStart: undefined,
          selectedTaskCreateDateEnd: undefined,
          isMultiselect: false,
        },
        this.getData
      );
      this.props.history.push("/TaskTracking?OwnTasks");
    }
    
        //Onayladığım talepler
        if (id === "selectedMainDropdown" && value === 7) {
          this.setState(
            {
              selectedMainDropdown: value,
              isLoading: true,
              IsFinishedTasks: false,
              
              IsDepartmentTasks: false,
              IsAllTasks: false,
              IsPoolTasks: false,
              confirmTasks:true,
              rejectTask:false,
              IsMyTasks: false,
              IsWatcherTasks: false,
              selectedTaskCreateDateStart: undefined,
              selectedTaskCreateDateEnd: undefined,
              isMultiselect: false,
            },
            this.getData
          );
          this.props.history.push("/TaskTracking");
        } 
        //Reddettiğim Talepler
        if (id === "selectedMainDropdown" && value === 8) {
          this.setState(
            {
          
              selectedMainDropdown: value,
              isLoading: true,
              selectedTaskStatus: "00000000-0000-0000-0000-000000000000",
              IsFinishedTasks: false,
              IsDepartmentTasks: false,
              IsAllTasks: false,
              confirmTasks:false,
              rejectTask:true,
              IsPoolTasks: false,
              IsMyTasks: false,
              IsWatcherTasks: false,
              selectedTaskCreateDateStart: undefined,
              selectedTaskCreateDateEnd: undefined,
              isMultiselect: false,
            },
            this.getData
          );
          this.props.history.push("/TaskTracking");
      }
      //Görev Atanan Talepler
      if (id === "selectedMainDropdown" && value === 9) {
          let IsAssigment = true;
          this.setState(
              {
                  selectedMainDropdown: value,
                  isLoading: true,
                  IsAllTasks: false,
                  IsAssigment: IsAssigment,
                  IsWatcherTasks:false,
                  selectedTaskStatus: "00000000-0000-0000-0000-000000000000",
                  IsFinishedTasks: false,
                  IsDepartmentTasks: false,
                  confirmTasks: false,
                  rejectTask: false,
                  IsPoolTasks: false,
                  IsMyTasks: false,
                  selectedTaskCreateDateStart: undefined,
                  selectedTaskCreateDateEnd: undefined,
                  isMultiselect: false,
              },
              this.getData
          );
          this.props.history.push("/TaskTracking?TaskAssignment");
      }
  }
 TaskCanceled(taskID){
  this.props.actions.isLoading(true);

  callAPI(
    callApiGet,
    "api/DataCheck/OverCheck?TaskID=" + taskID,
    {},
    (res) => {
      if (res.data === true) {
        this.props.actions.isLoading(false);

        Swal.fire({
          title: this.languages.iptalAciklamasıGiriniz,
          input: "textarea",
          inputAttributes: {
            "aria-label": "Type your message here",
          },
          showCancelButton: true,
          confirmButtonText: this.languages.tamam,
          cancelButtonText: this.languages.vazgec,
          showLoaderOnConfirm: true,
          
          preConfirm: (login) => {
            this.props.actions.isLoading(true);

            callAPI(
              callApiGet,
              "/api/TaskDetail/RejectTaskByOwner?TaskID=" +
                taskID +
                "&StatusID=AA2DF37B-8AC8-E811-80F6-005056B839BB&Description=" +
                login,
              {},
              (data) => {
                this.props.actions.isLoading(false);
                return data.data;
              }
                              

            );
            this.props.actions.isLoading(false);

          },
          
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(true);

            this.getData();

            Swal.fire({
              position: "center",
              icon: "success",
              title: this.languages.talepBasariylaIptalEdilmistir ,
              showConfirmButton: false,
              timer: 1500,
            });
            this.props.actions.isLoading(false);
          }
        });
      } else {
        this.props.actions.isLoading(false);

        timeoutPopup(
         this.languages.talebinizBaşkaBirKullanıcıTarafındanDevranıldığıİçinBuTalepÜzerindeİptalİşlemiYapamazsınız ,
          "warning",
         this.languages.tamam,
          6000
        );
      }
    }
  );
    }
    
  onToolbarPreparing = (e) => {
    let ths = this;
    let {
      showDetail,
      selectedMainDropdown,
      isLoading,
      selectedTaskCreateDateStart,
      selectedTaskCreateDateEnd,
      selectedTaskResponsibleUser,
      departmentUsers,
      taskStatus,
      selectedTaskStatus,
      departmentTaskStatus,
      isPopupVisible,
      selectedPools,
      myPools,
      isPopupVisible2
    } = this.state;
    
    let toolbarItems = e.toolbarOptions.items;
    let totalRow =this.languages.toplamTalepSayisi ;
    let arr = [];
    totalRow += ths.props.gridData ? ths.props.gridData.length : 0;



    if (this.state.loginUser && this.state.loginUser.IsLastUser === "True"&& this.state.loginUser.IsManager ==="False") {

      if (this.props.location.search === "?OwnTasks") {
        selectedMainDropdown = 6;
      }
       else if (this.props.location.search === "?WatcherTasks") {
        selectedMainDropdown = 5;
        }
      else if (this.props.location.search === "?TaskAssignment") {
          selectedMainDropdown = 9;
      }
      arr = [
        {
          ID: 5,
          Name:this.languages.izleyicisiOlduğumTalepler ,
        },
        {
          ID: 6,
          Name: this.languages.oluşturduğumTalepler,
        },
      ];
    } 
    else if (this.state.loginUser && this.state.loginUser.IsManager ==="True" && this.state.loginUser.IsLastUser === "True"){
      arr = [
       
      
       
        {
          ID: 6,
          Name: this.languages.oluşturduğumTalepler,
        },
        {
          ID: 5,
          Name:this.languages.izleyicisiOlduğumTalepler ,
        },
        {
          ID: 7,
          Name: this.languages.onayladıgımTalepler,
        },
        {
          ID: 8,
          Name: this.languages.reddettiğimTalepler,
        },
      ];
    }
    else if (this.state.loginUser && this.state.loginUser.IsManager ==="True"){
      arr = [
        {
          ID: 1,
          Name: this.languages.üzerimdekiTalepler,
        },
        {
          ID: 2,
          Name: this.languages.ekibinÜzerindekiTalepler,
        },
        {
          ID: 3,
          Name: this.languages.tamamlananTalepler,
        },
        {
          ID: 6,
          Name: this.languages.oluşturduğumTalepler,
        },
        {
          ID: 5,
          Name: this.languages.izleyicisiOlduğumTalepler ,
        },
        {
          ID: 7,
          Name: this.languages.onayladıgımTalepler,
        },
        {
          ID: 8,
          Name: this.languages.reddettiğimTalepler,
        },
        {
            ID: 9,
            Name: this.languages.görevAtananTalepler,
        },
      ];
    }
    else {
      arr = [
        {
          ID: 1,
          Name: this.languages.üzerimdekiTalepler,
        },
        {
          ID: 2,
          Name: this.languages.ekibinÜzerindekiTalepler,
        },
        {
          ID: 3,
          Name: this.languages.tamamlananTalepler,
        },
        {
          ID: 6,
          Name: this.languages.oluşturduğumTalepler,
        },
        {
          ID: 5,
          Name: this.languages.izleyicisiOlduğumTalepler ,
        },
        {
            ID: 9,
            Name: this.languages.görevAtananTalepler,
        },
        // // {
        // //   ID: 7,
        // //   Name: "Onayladığım Talepler",
        // // },
        // // {
        // //   ID: 8,
        // //   Name: "Reddettiğim Talepler",
        // // },
      ];
    }



    //Main DropDown
    toolbarItems.unshift({
      widget: "dxSelectBox",
      options: {
        dataSource: arr,
        valueExpr: "ID",
        displayExpr: "Name",
        value: selectedMainDropdown,
        onValueChanged: function (e) {
          ths.gridRef.current.instance.clearFilter();
          ths.handleChange("selectedMainDropdown", e.value);
        },
        width: 180,
        textColor: "white",
        validationstatus: isLoading === true ? "pending" : "valid",
      },
      location: "before",
    });





    //Toplam Checkbox
    toolbarItems.unshift({
      location: "center",
      template: "totalGroupCount",
    });

    if (selectedMainDropdown === 1) {

      toolbarItems.push({
        widget: "dxSelectBox",
        options: {
          dataSource: taskStatus,
          valueExpr: "ID",
          displayExpr: "Name",
          value: selectedTaskStatus,
          onValueChanged: function (e) {
            
            ths.setState(
              { selectedTaskStatus: e.value, isLoading: true },
              ths.getData
            );
          },
          width: 170,
          textColor: "white",
          validationstatus: isLoading === true ? "pending" : "valid",
        },
        location: "before",
      });
    }



    if (selectedMainDropdown === 1) {

      toolbarItems.push({
        widget: "dxSelectBox",
      options: {
        dataSource: myPools,
        valueExpr: "ID",
        displayExpr: "Name",
        value: selectedPools,
        onValueChanged: function (e) {
         
          ths.setState(
            { selectedPools: e.value, isLoading: true },
            ths.getData
          );
        },
        width: 180,
        textColor: "white",
        validationstatus: isLoading === true ? "pending" : "valid",
      },
      location: "before",
      });
        toolbarItems.push({
            widget: "dxButton",
            options: {
                text: "Toplu Talep Sonlandır",
                onClick: function () {
                    const dataGrid = ths.gridRef.current.instance;
                    if (!dataGrid) {
                        console.error("DataGrid bulunamadı.");
                        return;
                    }
                    const selectedRowsData = dataGrid.getSelectedRowsData() || [];
                    const selectedItemsList = selectedRowsData.map(row => ({
                        TaskNumber: row.TaskNumber,
                        CategoryID: row.CategoryID,
                        StatusID: row.StatusID,
                        TaskID: row.TaskID,
                    }));

                    if (selectedItemsList.length > 0) {
                        const allValid = selectedItemsList.every(item => item.StatusID === "ac2df37b-8ac8-e811-80f6-005056b839bb");
                        if (allValid) {
                            const categoryID = selectedItemsList[0].CategoryID;
                            console.log("aaa", selectedItemsList[0]);
                            ths.openPopup(categoryID, selectedItemsList); 
                        } else {
                            timeoutPopup(
                                ths.languages.TalepişlemdurumutalebikapatmayauygundeğilÖncelikleİşlemeAlınız,
                                "warning",
                                ths.languages.tamam
                            );
                        }
                    } else {
                        console.log("Hiçbir satır seçilmedi.");
                    }
                },
            },
            location: "before",
        });
    }
    
    //Onayladıgım talepeler
    if (selectedMainDropdown === 7) {
      toolbarItems.push({
        widget: "dxSelectBox",
        options: {
          dataSource: taskStatus,
          valueExpr: "ID",
          displayExpr: "Name",
          value: this.state.selectedTaskStatus,
          // "00000000-0000-0000-0000-000000000000"
          onValueChanged: function (e) {
            
            ths.setState(
              { selectedTaskStatus: e.value, isLoading: true,selectedTaskStatusForConfirm:e.value},
              ths.getData
            );
          },
          width: 170,
          textColor: "white",
          validationstatus: isLoading === true ? "pending" : "valid",
        },
        location: "before",
      });
    }

    if (selectedMainDropdown === 2) {
      toolbarItems.push({
        widget: "dxSelectBox",
        options: {
          dataSource: departmentTaskStatus,
          valueExpr: "ID",
          displayExpr: "Name",
          value: selectedTaskStatus,
          onValueChanged: function (e) {
            
            ths.setState(
              { selectedTaskStatus: e.value, isLoading: true },
              ths.getData
            );
          },
          width: 180,
          textColor: "white",
          validationstatus: isLoading === true ? "pending" : "valid",
        },
        location: "before",
      });

      toolbarItems.push({
        widget: "dxButton",
        options: {
          text: this.languages.detaylıFiltre,
          showClearButton: true,
          onClick: function (e) {
            
            //togglePopup();
            ths.setState({ isPopupVisible: !ths.state.isPopupVisible });
          },
          width: 140,
        },
        location: "before",
      });
    }

    //Tamamını Görüntüle Seçinise
    if (selectedMainDropdown === 3) {
      toolbarItems.push({
        widget: "dxSelectBox",
        options: {
          dataSource: departmentUsers,
          valueExpr: "ID",
          displayExpr: "Name",
          value: selectedTaskResponsibleUser,
          onValueChanged: function (e) {
            ths.setState(
              { selectedTaskResponsibleUser: e.value, isLoading: true },
              ths.getData
            );
          },
          width: 140,
          textColor: "white",
          validationstatus: isLoading === true ? "pending" : "valid",
        },
        location: "before",
      });

      toolbarItems.push({
        widget: "dxDateBox",
        options: {
          placeholder:
            selectedMainDropdown !== 3
              ? this.languages.oluşturmaBaşlangıçTarihi
              : this.languages.kapanışBaşlangıçTarihi,
          value: selectedTaskCreateDateStart,
          showClearButton: true,
          onValueChanged: function (e) {
            if (e.value) {
              ths.setState(
                { selectedTaskCreateDateStart: e.value, isLoading: true },
                ths.getData
              );
            }
          },
          width: 140,
          validationstatus: !selectedTaskCreateDateStart ? "invalid" : "valid",
        },
        location: "before",
      });

      toolbarItems.push({
        widget: "dxDateBox",
        options: {
          placeholder:  
            selectedMainDropdown !== 3
              ? this.languages.oluşturmaBitişTarihi
              : this.languages.kapanışBitişTarihi,
          value: selectedTaskCreateDateEnd,
          showClearButton: true,
          onValueChanged: function (e) {
            if (e.value) {
              ths.setState(
                { selectedTaskCreateDateEnd: e.value, isLoading: true },
                ths.getData
              );
            }
          },
          width: 140,
          validationstatus: !selectedTaskCreateDateEnd ? "invalid" : "valid",
        },
        location: "before",
      });
    }


    //Kapananları Dahil et Seçinise
    if (selectedMainDropdown === 6) {
      toolbarItems.push({
        widget: "dxDateBox",
        options: {
          placeholder:
            selectedMainDropdown !== 3
              ? this.languages.oluşturmaBaşlangıçTarihi
              : this.languages.kapanışBitişTarihi,
          value: selectedTaskCreateDateStart,
          showClearButton: true,
          onValueChanged: function (e) {
            if (e.value) {
              ths.setState(
                { selectedTaskCreateDateStart: e.value, isLoading: true },
                ths.getData
              );
            }
          },
          width: 140,
          validationstatus: !selectedTaskCreateDateStart ? "invalid" : "valid",
        },
        location: "before",
      });

      toolbarItems.push({
        widget: "dxDateBox",
        options: {
          placeholder:
            selectedMainDropdown !== 3
            ? this.languages.oluşturmaBitişTarihi
            : this.languages.kapanışBitişTarihi,
          value: selectedTaskCreateDateEnd,
          showClearButton: true,
          onValueChanged: function (e) {
            if (e.value) {
              ths.setState(
                { selectedTaskCreateDateEnd: e.value, isLoading: true },
                ths.getData
              );
            }
          },
          width: 140,
          validationstatus: !selectedTaskCreateDateEnd ? "invalid" : "valid",
        },
        location: "before",
      });

      toolbarItems.push({
        widget: "dxCheckBox",
        options: {
          text: this.languages.kapananlarıDahilEt,
          value: this.state.showClosedTasks,
          showClearButton: true,
          color: "white",
          onValueChanged: function (e) {
            ths.setState(
              { showClosedTasks: e.value, isLoading: true },
              ths.getData
            );
          },
          width: 160,
          validationstatus: !isLoading ? "invalid" : "valid",
        },
        location: "before",
      });
    }




    if (selectedMainDropdown === 5) {
      toolbarItems.push({
        widget: "dxCheckBox",
        options: {
          text: this.languages.kapananlarıDahilEt,
          value: this.state.showClosedTasks,
          showClearButton: true,
          color: "white",
          onValueChanged: function (e) {
            ths.setState(
              { showClosedTasks: e.value, isLoading: true },
              ths.getData
            );
          },
          width: 160,
          validationstatus: !isLoading ? "invalid" : "valid",
        },
        location: "before",
      });
    }
  };  

  toolbarItemRender = (e) => {
    return (
      <div className="informer">
        <h3 className="count">
          {this.props.gridData ? this.props.gridData.length : "0"}
        </h3>
        <span className="name">{this.languages.toplamTalepSayisi}</span>
      </div>
    );
  };

  clearFilter() {
    this.setState({
      //selectedCompany: null,

      selectedDegreeOfUrency: "",
      selectedPoolID: null,
      selectedExpectedEndDate: undefined,
      selectedShowAll: false,
    });
  }

  render() {
    let ths = this;
      const { t } = this.props;
      const { visibleSonlandir, isPopupVisible, categoryID, statusID } = this.state;
      const { customElements } = this.state;
    const {
      
      searchModeOption,
      searchExprOption,
      minSearchLengthOption,
      showDataBeforeSearchOption,
      searchTimeoutOption,
      } = this.state;

      const companiesDataSource = new DataSource({
          store: {
              data: this.state.companies,
              type: 'array',
              key: 'ID'
          },
          sort: [{ selector: "Name", desc: false }]
      });
    
    return (      
      <>      
        <Row>
          <Col xs="12" sm="12">            
            <Popup
              visible={this.state.isPopupVisible}
              closeOnOutsideClick={true}
              onHiding={() =>
                this.setState({ isPopupVisible: false })                
              }
              showTitle={true}
              title= {this.languages.detaylıFiltre}
              width={500}
              height={700}
              resizeEnabled={true}
              dragEnabled={true}
              position="center"
            >              
             
               
               <p>{this.languages.talepDurumu}</p>
              <SelectBox
                dataSource={this.state.departmentTaskStatus}
                valueExpr="ID"
                displayExpr="Name"
                value={this.state.selectedTaskStatus}
                onValueChanged={function (e) {
                  ths.setState(
                    { selectedTaskStatus: e.value },
                   
                  );
                }}
                width={400}
                textColor="white"
               
              ></SelectBox> <br></br>
              <p>{this.languages.işlemDurumu}</p>
              <SelectBox
                dataSource={this.state.workStatus}
                valueExpr="ID"
                displayExpr="Name"
                value={this.state.selectedWorkStatus}
                onValueChanged={function (e) {
                  ths.setState(
                    { selectedWorkStatus: e.value}
                  );
                }}
                width={400}
                textColor="white"
                
              ></SelectBox> <br></br>
              <p>{this.languages.firma}</p>
              <SelectBox
                dataSource={companiesDataSource}
                valueExpr="ID"
                displayExpr="Name"
                searchEnabled={true}
                searchMode={searchModeOption}
                searchExpr={searchExprOption}
                searchTimeout={searchTimeoutOption}
                minSearchLength={minSearchLengthOption}
                showDataBeforeSearch={showDataBeforeSearchOption}
                value={this.state.selectedCompany}
                
                onValueChanged={function (e) {
                  ths.setState(
                    { selectedCompany: e.value },
                   
                  );
                }}
                width={400}
                textColor="white"
                
              ></SelectBox> <br></br>
              <p>{this.languages.ProjeNumarası}</p>
              <div style={{marginRight:'260px'}}>{BB.InputWithLabel('projectNumber', '', 'text', ths)}</div>
              <p>{this.languages.oluşturmaBaşlangıçTarihi}</p>
              <DateBox
                placeholder={
                  this.state.selectedMainDropdown !== 3
                  ? this.languages.oluşturmaBaşlangıçTarihi
                  : this.languages.kapanışBitişTarihi

                }
                value={this.state.selectedTaskCreateDateStart}
                showClearButton={true}
                onValueChanged={function (e) {
                  if (e.value) {
                    ths.setState(
                      { selectedTaskCreateDateStart: e.value },
                     
                    );
                  }
                }}
                width={200}
                
              ></DateBox><br></br>
               <p>{this.languages.oluşturmaBitişTarihi}</p>
              <DateBox
                placeholder={
                  this.state.selectedMainDropdown !== 3
                    ? this.languages.oluşturmaBitişTarihi
                    : this.languages.kapanışBitişTarihi
                }
                value={this.state.selectedTaskCreateDateEnd}
                showClearButton={true}
                onValueChanged={function (e) {
                  if (e.value) {
                    ths.setState(
                      { selectedTaskCreateDateEnd: e.value},
                      
                    );
                  }
                }}
                width={200}
                
              ></DateBox><br></br>
               
              
                 <Button 
                  style={{marginLeft:'350px'}}
                  type="button"
                  className="btn btn-secondary btn-lg"
                  validationstatus={
                    this.state.isLoading === true ? "pending" : "valid"
                  }
                  onClick={()=>{
                    this.setState({ isPopupVisible: !this.state.isPopupVisible, isLoading:true },ths.getData)
                            }}>{this.languages.uygula}</Button> 

                    </Popup>
                    <TaskTerminationModal t={this.props.t} event={this} />
            <CustomCard cardStyle="card-dashboard-calendar pb-0">
              <CardBody>
                {this.state.selectedMainDropdown === 5
                  ? BB.DxDataGridForOwnTaskTracking(
                      this.props.gridData,
                      this,
                      this.state.loginUser,
                      this.state.showDetail
                    )
                  : BB.DxDataGridForOwnTaskTracking(
                      this.props.gridData,
                      this,
                      this.state.loginUser,
                      this.state.showDetail
                    )}
              </CardBody>
            </CustomCard>
                </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    gridData: state.taskReducer,
    loginUser: state.loginReducer,
      watcherTasks: state.getWatcherTasksReducer,
      taskAssigned: state.getTaskAssignedReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getTaskInTaskTracking: bindActionCreators(
        taskActions.getAllTask,
        dispatch
      ),
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
    },
  };
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskTracking))
);
